/* You can add global styles to this file; and also import other style files */
@import "assets/vendors/general/perfect-scrollbar/css/perfect-scrollbar.css";
@import "assets/vendors/general/tether/dist/css/tether.css";
@import "assets/vendors/general/bootstrap-datepicker/dist/css/bootstrap-datepicker3.css";
@import "assets/vendors/general/bootstrap-datetime-picker/css/bootstrap-datetimepicker.css";
@import "assets/vendors/general/bootstrap-timepicker/css/bootstrap-timepicker.css";
@import "assets/vendors/general/bootstrap-daterangepicker/daterangepicker.css";
@import "assets/vendors/general/bootstrap-touchspin/dist/jquery.bootstrap-touchspin.css";
@import "assets/vendors/general/bootstrap-select/dist/css/bootstrap-select.css";
@import "assets/vendors/general/nouislider/distribute/nouislider.css";
@import "assets/vendors/general/owl.carousel/dist/assets/owl.carousel.css";
@import "assets/vendors/general/owl.carousel/dist/assets/owl.theme.default.css";
@import "assets/vendors/general/dropzone/dist/dropzone.css";
@import "assets/vendors/general/summernote/dist/summernote.css";
@import "assets/vendors/general/bootstrap-markdown/css/bootstrap-markdown.min.css";
@import "assets/vendors/general/animate.css/animate.css";
@import "assets/vendors/general/toastr/build/toastr.css";
@import "assets/vendors/general/morris.js/morris.css";
@import "assets/vendors/general/sweetalert2/dist/sweetalert2.css";
@import "assets/vendors/general/socicon/css/socicon.css";
@import "assets/vendors/custom/vendors/line-awesome/css/line-awesome.css";
@import "assets/vendors/custom/vendors/flaticon/flaticon.css";
@import "assets/vendors/custom/vendors/flaticon2/flaticon.css";
@import "assets/vendors/custom/datatables/datatables.bundle.min.css";
@import "assets/custom/user/login-v1.css";
@import "assets/base/style.bundle.css";
@import "assets/skins/header/OPS.css";
@import '~quill/dist/quill.core.css';
@import 'assets/vendors/custom/quill/quill.orion.css';

/* FontAwesome */
@import "../../../node_modules/@fortawesome/fontawesome-pro/css/all.min.css";

/* OAT Styles */
@import "../../../node_modules/@oat/stylesheets/src/default/variable-bs-overrides";
@import "../../../node_modules/@oat/opl-theme/src/theme/custom/_variable-custom-dark";
@import "../../../node_modules/@oat/opl-theme/src/theme/custom/_variable-custom-light";


/* PrimeNG */
@import '../../../node_modules/primeng/resources/primeng.min';
@import "@oat/opl-theme/src/theme/theme";
@import "../../../node_modules/primeflex/primeflex.scss";
@import "../../../node_modules/primeicons/primeicons.css";

/* Ag Grid */
@import "../../../node_modules/ag-grid-community/src/styles/ag-grid.scss";
@import "../../../node_modules/ag-grid-community/src/styles/ag-theme-balham-dark/sass/ag-theme-balham-dark.scss";
@import "../../../node_modules/ag-grid-community/src/styles/ag-theme-alpine-dark/sass/ag-theme-alpine-dark.scss";
@import "../../../node_modules/ag-grid-community/src/styles/ag-theme-alpine/sass/ag-theme-alpine.scss";

:root {
  color-scheme: only dark;
}

/* Custom styles and overrides */
.ql-editor {
  font-family: Segoe UI;
  font-size: 13px;
}

.notification-item {
  padding: 15px 12px 15px 12px;
  margin: 5px;
  background: #424345;
  border-radius: 2px;
  position: relative;
}

.notification-preference {
  padding: 10px 12px 10px 12px;
  margin: 5px;
  background: #424345;
  border-radius: 2px;
  position: relative;

  .row {
    margin-right: inherit;
  }
}

.notification-sidebar {
  min-width: 600px;
  background: #343a40;
  color: var(--text-primary);

  .p-sidebar-content {
    padding: 0;
    margin: 0;
    overflow: hidden;

    .form-group {
      padding: 0;
      margin: 0 0 15px;
    }
  }
}

.p-sidebar-no-header .p-sidebar-header {
  display: none;
}

.ag-cell.pointer-cursor {
  cursor: pointer;
}

.ql-snow .ql-editor a {
  text-decoration: none !important;
}

.p-multiselect-label {
  min-width: 15rem !important;
  max-width: 100% !important;
  max-height: 5.5rem !important;
  overflow: hidden !important;
  text-wrap: inherit !important;
}

.p-tabview-title {
  font-size: 13px;
}

.stretch-height {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
}

div[class~='p-dialog-content']:has(legal-agreement) {
  overflow-y: auto !important;
}

.fixed-header {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: var(--modal-background-base);
}


.communities-dialog-mfe {
	//Force the dialog vertical scrollbar to be present if needed.
  .p-dialog-content {
    overflow-y: auto !important;
  }
}
